<template>
  <component
    :is="tag"
    :class="[computedClasses, { 'border-btn': showBorder }]"
    :type="tag === 'button' ? type : undefined"
    :disabled="isDisabled"
    :to="href"
    ><slot
  /></component>
</template>

<script lang="ts" setup>
import type { NuxtLinkOptions } from '#app'
import type { DefineComponent } from 'vue'

const props = withDefaults(
  defineProps<{
    variant?: 'primary' | 'primary-350' | 'highlight' | 'success' | 'warning' | 'danger' | 'white'
    size?: 'sm' | 'md' | 'lg'
    outline?: boolean
    link?: boolean
    icon?: boolean
    block?: boolean
    type?: 'button' | 'submit' | 'a' | 'nuxt-link'
    label?: boolean
    disabled?: boolean
    href?: string
    showBorder?: boolean
  }>(),
  { size: 'md', type: 'button', disabled: false }
)

const tag = computed<string | DefineComponent<NuxtLinkOptions>>(() => {
  if (props.href) return defineNuxtLink({})
  switch (props.type) {
    case 'nuxt-link':
      return defineNuxtLink({})
    default:
      return props.type
  }
})

const loading = ref(true)
const isDisabled = computed(() => loading.value || props.disabled)

onMounted(() => {
  loading.value = false
})

const computedClasses = computed(() => {
  if (props.link) {
    return [
      'zlink',
      `zlink-${props.size}`,
      `zlink-${props.variant ?? 'highlight'}`,
      {
        'zlink-block': props.block
      }
    ]
  } else if (props.label) {
    return [
      'zbtn-label',
      `zbtn-label-${props.size}`,
      {
        'zbtn-label-block': props.block
      }
    ]
  } else {
    return [
      'zbtn',
      `zbtn-${props.variant ?? 'primary'}${props.outline ? '-outline' : ''}`,
      `zbtn-${props.size}${props.icon ? '-icon' : ''}`,
      {
        'zbtn-block': props.block
      }
    ]
  }
})
</script>

<style lang="scss" scoped>
.zlink {
  display: inline-block;
  text-decoration: underline;
  color: $highlight-500;
  cursor: pointer;

  &[type] {
    // reset buttons
    border: none;
    background: none;
  }

  // Colors
  $linkVariants: (
    'primary': $primary-500,
    'primary-350': $primary-350,
    'highlight': $highlight-500,
    'success': $success-700,
    'warning': $warning-500,
    'danger': $danger-500,
    'white': #fff
  );

  @each $variantName, $textColor in $linkVariants {
    &-#{$variantName} {
      color: $textColor;
    }
  }

  &:hover {
    color: #1f57c6;
  }

  // Size
  &-sm {
    padding: 0.5rem 0;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  &-md {
    padding: 0.75rem 0;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5rem;
  }

  &-lg {
    padding: 1rem 0;
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.5rem;
  }

  // Layout
  &-block {
    width: 100%;
  }
}

.border-btn {
  border-radius: 1.5rem;
  border: 1px solid $primary-100;
}
</style>
