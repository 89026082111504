import { Loader } from '@googlemaps/js-api-loader'
import type { IGooglePlace } from '~/types/common/google-place'

const autocompleteService = ref<google.maps.places.AutocompleteService | undefined>()
const geocoderService = ref<google.maps.Geocoder | undefined>()

export function parseGooglePlace({
  place,
  geocode
}: {
  place?: google.maps.places.AutocompletePrediction
  geocode: google.maps.GeocoderResult
}): IGooglePlace {
  const formattedPlace: IGooglePlace = {
    searchable: true,
    placeId: geocode.place_id,
    center: (geocode.geometry.location.toJSON
      ? geocode.geometry.location.toJSON()
      : geocode.geometry.location) as google.maps.LatLngLiteral,
    bounds: (geocode.geometry.bounds?.toJSON
      ? geocode.geometry.bounds?.toJSON()
      : geocode.geometry.bounds) as google.maps.LatLngBoundsLiteral,
    mainText:
      place?.structured_formatting.main_text ??
      (geocode.address_components
        .filter((component) => ['street_number', 'route'].includes(component.types[0]))
        .map((component) => component.long_name)
        .join(' ') ||
        'Unknown'),
    postalCode: geocode.address_components.find((component) => component.types.includes('postal_code'))?.long_name,
    secondaryText: place?.structured_formatting.secondary_text ?? '',
    types: place?.types ?? geocode?.types ?? [],
    fullName: '',
    place,
    geocode
  }

  geocode.address_components.forEach((addrComponent) => {
    if (addrComponent.types.includes('locality')) {
      formattedPlace.city = addrComponent
    } else if (addrComponent.types.includes('administrative_area_level_1')) {
      formattedPlace.region = addrComponent
    } else if (addrComponent.types.includes('country')) {
      formattedPlace.country = addrComponent
    }
  })

  formattedPlace.fullName =
    place?.description ??
    [formattedPlace.city?.long_name, formattedPlace.region?.short_name, formattedPlace.country?.long_name]
      .filter((x) => x)
      .join(', ')

  return formattedPlace
}

export const searchPlaces = async (
  input: string,
  countryRestrictions: string[] = ['ca', 'us']
): Promise<google.maps.places.AutocompletePrediction[]> => {
  await loadAutocompleteService()

  if (!input || !autocompleteService.value) return []

  try {
    const response = await autocompleteService.value.getPlacePredictions({
      input,
      componentRestrictions: {
        country: countryRestrictions
      }
    })

    return response.predictions
  } catch (e) {
    return []
  }
}

export const getGeocode = async (placeId: string): Promise<google.maps.GeocoderResult | undefined> => {
  await loadGeocoderService()

  try {
    const geocodingResponse = await geocoderService.value?.geocode({ placeId })

    return geocodingResponse?.results[0]
  } catch (e) {
    return
  }
}

export const getGooglePlaceByName = async (input: string): Promise<IGooglePlace | undefined> => {
  const places = await searchPlaces(input)
  if (!places.length) return

  const place = places[0]
  const geocode = await getGeocode(place.place_id)
  if (!geocode) return

  return parseGooglePlace({ place, geocode })
}

const googleMapsLoader = (): Loader => useNuxtApp().$googleMapsLoader as Loader

export const loadAutocompleteService = async (): Promise<void> => {
  const { AutocompleteService } = (await googleMapsLoader().importLibrary('places')) as google.maps.PlacesLibrary

  autocompleteService.value = new AutocompleteService()
}

export const loadGeocoderService = async (): Promise<void> => {
  if (geocoderService.value) return

  const { Geocoder } = (await googleMapsLoader().importLibrary('geocoding')) as google.maps.GeocodingLibrary
  geocoderService.value = new Geocoder()
}
